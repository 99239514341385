/* eslint-disable complexity */
import React, {useEffect, useRef, useState, Fragment} from 'react';
import reactStringReplace from 'react-string-replace';
import classnames from 'classnames';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {setToStorage} from '../../storage';
import {getIsUSGeoGroup} from '../../api';
import {Logo} from '../Logo';
import {RestorePurchaseModal} from '../RestorePurchaseModal';
import BlackFridaySaleImage from './assets/blackFridaySale.png';
import styles from './styles.scss';

const parseTextField = (text, isMobile) => {
  if (!text) {
    return null;
  }

  let newText = text;

  newText = reactStringReplace(newText, ':mailplusLogo:', (_, index) =>
    <Logo type='mailPlus' color='white' styles={styles.titleLogo} key={`logo_${index}`} />
  );

  newText = reactStringReplace(newText, ':mobileLineBreak:', (_, index) => {
    if (isMobile) {
      return <br key={`mbr_${index}`} />;
    }

    return ' ';
  });

  newText = reactStringReplace(newText, ':desktopLineBreak:', (_, index) => {
    if (isMobile) {
      return ' ';
    }

    return <br key={`dbr_${index}`} />;
  });

  newText = reactStringReplace(newText, /~~([^~]+)~~/g, (match, index) =>
    <span className={styles.strikeThrough} key={`strikethrough_${index}`}>{match}</span>
  );

  newText = reactStringReplace(newText, ':blackFridaySale:', (_, index) =>
    <img
      loading='lazy'
      className={styles.blackFridaySale}
      src={BlackFridaySaleImage}
      width={968}
      height={452}
      alt='black friday sale'
      key={`blackFridaySale_${index}`}
    />
  );

  return newText;
};

const hasFeatureFlag = (featureFlags, flag) => featureFlags && Array.isArray(featureFlags) && featureFlags.includes(flag);

// eslint-disable-next-line arrow-body-style
export const InlinePaywall = ({
  signUpTitle,
  signUpPretitle,
  signUpTopStripText,
  signUpUrl,
  signUpButtonText,
  signUpButtonSubtitle,
  signUpBullets,
  theme,
  isLoggedIn,
  loginUrl,
  restorePurchaseUrl,
  paywallOutcomeLabel,
  renderPlatform,
  featureFlags,
  shortGradient,
  entitlementFetchError
}) => {
  const elementRef = useRef(null);
  const [isRestorePurchaseModalOpen, setIsRestorePurchaseModalOpen] = useState(false);
  const isTim = theme === 'thisismoney';
  const isUS = getIsUSGeoGroup();
  const isMobile = renderPlatform === 'mobile';
  const isBulletsBeforeButton = hasFeatureFlag(featureFlags, 'bullets_before_button');
  const isSummerTheme = hasFeatureFlag(featureFlags, 'summer_theme');
  const isPrimeTheme = hasFeatureFlag(featureFlags, 'prime_theme');
  const isBulletsDisc = hasFeatureFlag(featureFlags, 'bullets_disc');

  const handleSignUpClick = () => {
    setToStorage('return-url', location.href);
    setToStorage('purchase-meta', {
      paywallOutcomeLabel,
      referringModule: 'paywall',
      referringPageType: 'paidArticle'
    });
    pageEvents.publish(pageEvents.PAYWALL_SIGN_UP, {paywallOutcomeLabel});
  };

  const handlerRestorePurchaseLinkClick = (event) => {
    const flowVariant = 'paywall restore purchase';

    event.preventDefault();
    setIsRestorePurchaseModalOpen(true);
    setToStorage('restore-meta', {flowVariant});
    pageEvents.publish(pageEvents.PAYWALL_RESTORE_PURCHASE_CLICK, {flowVariant});
  };

  const handleLoginClick = () => {
    pageEvents.publish(pageEvents.PAYWALL_LOGIN_CLICK, {paywallOutcomeLabel});
  };

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const handleIntersect = (entries, observerInHandler) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          pageEvents.publish(pageEvents.PAYWALL_VIEWABLE, {paywallOutcomeLabel});
          observerInHandler.disconnect();

          return;
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect);

    observer.observe(elementRef.current);

    pageEvents.publish(pageEvents.PAYWALL_IMPRESSION, {paywallOutcomeLabel});

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, []);

  const buttonComponent =
    <a onClick={handleSignUpClick} className={styles.signUpButton} href={signUpUrl} key='signupButton' data-test-id='signupButton'>
      <div>{parseTextField(signUpButtonText, isMobile)}</div>
      {signUpButtonSubtitle &&
        <div className={styles.signUpButtonSubtitle}>{parseTextField(signUpButtonSubtitle, isMobile)}</div>
      }
    </a>;

  const bulletsComponent = signUpBullets && signUpBullets.length > 0 &&
    <div className={classnames(styles.signUpBullets, isBulletsDisc && styles.bulletsDisc)} key='signupBullets'>
      {signUpBullets.map((bullet) =>
        <div key={bullet} className={styles.signUpBullet}>{bullet}</div>
      )}
    </div>;

  return (
    <Fragment>
      <div className={classnames(styles.wrapper, isTim && styles.thisismoney, isMobile && styles.isMobile, isSummerTheme && styles.summerTheme, isPrimeTheme && styles.primeTheme, isUS && !isTim && styles.isUS)} ref={elementRef}>
        <div className={classnames(styles.gradient, shortGradient && styles.shortGradient)} />
        <div className={styles.signUp}>
          {signUpTopStripText &&
            <div className={styles.signUpTopStripText}>
              {parseTextField(signUpTopStripText, isMobile)}
            </div>
          }
          <div className={styles.signUpInner}>
            <Logo styles={styles.logo} color='white' type={isTim ? 'tim' : 'mailPlus'} />
            {signUpPretitle && <div className={styles.signUpPretitle}>{parseTextField(signUpPretitle, isMobile)}</div>}
            <div className={styles.signUpTitle}>{parseTextField(signUpTitle, isMobile)}</div>
            {isBulletsBeforeButton ?
              [bulletsComponent, buttonComponent] :
              [buttonComponent, bulletsComponent]
            }
          </div>
        </div>
        {!isLoggedIn &&
          <div className={styles.login}>
            Already a subscriber? <a onClick={handleLoginClick} href={loginUrl} className={styles.loginLink}>Log in</a>
          </div>
        }
        {isLoggedIn && entitlementFetchError &&
          <div className={styles.login}>
          Please <a className={styles.loginLink} href={restorePurchaseUrl}>log in again</a> to access your subscription
          </div>
        }
        {isLoggedIn && !entitlementFetchError &&
          <div className={styles.login}>
            Already a subscriber?
            {' '}
            <a
              className={styles.loginLink}
              data-test-id='restorePurchaseButton'
              href={restorePurchaseUrl}
              onClick={handlerRestorePurchaseLinkClick}
            >
              Click here
            </a>
          </div>
        }
      </div>
      {isRestorePurchaseModalOpen &&
        <RestorePurchaseModal
          restorePurchaseUrl={restorePurchaseUrl}
          onClose={() => setIsRestorePurchaseModalOpen(false)}
        />
      }
    </Fragment>
  );
};

/* eslint-disable import/max-dependencies */
/* eslint no-confusing-arrow: "off"*/
/* eslint max-nested-callbacks: "off"*/
/* eslint id-length: "off"*/

import {logger} from '@mol-fe/mol-fe-client-logger';
import classnames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {loadComments, loadMyComments, postComment} from '../common/comments';
import {getCommentStore} from '../common/cookies';
import {formatNumber} from '../common/format';
import {trackCommentLoadTime, trackCommentPaginationClick, trackCommentTab, trackCommentImpression} from '../common/tracking';
import {getUserId, isUserLoggedIn} from '../common/user';
import {useComment} from '../context/CommentContext';
import {useMount} from '../hooks/useMount';
import {openCommentsOverlay, scrollToComments} from '../utils';
import CommentList from './CommentList';
import InputForm from './InputForm';
import LoadingDots from './LoadingDots';
import Notification from './Notification';
import styles from './Page.module.scss';
import Spinner from './Spinner';
import Tab from './Tab';

const PAGE_SIZE = 10;

const TRACKING_TAB_VALUES = {
  bestRated: 'best',
  myComments: 'myComments',
  newest: 'newest',
  oldest: 'oldest',
  worstRated: 'worst'
};

export default ({tab, startLoadTime, firstLoad, setFirstLoad}) => {
  const {article, notification, addShadowComment, showErrorNotification, onClose, jumpToComment} = useComment();
  const [query, setQuery] = useState({
    offset: jumpToComment ? jumpToComment.offset : 0,
    tab
  });
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabChanged, setTabChanged] = useState(false);
  const [firstFetchMore, setFirstFetchMore] = useState(true);
  const [commentsCount, setCommentsCount] = useState(article.totalComments);

  const [paginate, setPaginate] = useState('hidden');
  const [nextOffset, setNextOffset] = useState(0);
  const [missingCommentsOffset, setMissingCommentsOffset] = useState(0);
  const [needToFetchMissing, setNeedToFetchMissing] = useState(false);

  const isMounted = useMount();

  // This cookie stuff is in case we needed to make the user logged in, so the comment gets
  // posted once he's redirected back to the comments page
  const postStoredComment = useCallback(async () => {
    if (!isUserLoggedIn()) {
      return;
    }

    const comment = getCommentStore();

    if (!comment) {
      return;
    }

    const success = await postComment(comment.articleId, comment.message, comment.replyTo);

    if (!isMounted.current) {
      return;
    }

    if (success) {
      addShadowComment(
        {
          parentId: comment.replyTo,
          replyId: undefined
        },
        comment.message);

      onClose();
      scrollToComments();
    } else {
      showErrorNotification({replyId: comment.replyTo});
    }
  }, []);

  const trackLoadTime = useCallback(() => {
    if (firstLoad) {
      const endLoadTime = Date.now();
      const totalLoadTime = endLoadTime - startLoadTime;

      trackCommentLoadTime(totalLoadTime);
      setFirstLoad(false);
    }
  }, [firstLoad, startLoadTime]);

  const onLoadComments = useCallback(async (value) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const {totalCount, result} = value.tab === 'myComments' ?
        await loadMyComments(article.id, {
          ...value,
          userId: getUserId()}) :
        await loadComments(article.id, value);

      if (isMounted.current) {
        setComments((prevComments) => {
          const mergeComments = !tabChanged;

          if (!mergeComments) {
            return result;
          }

          const updatedComments = [...prevComments];

          result.forEach((newComment) => {
            const existingCommentIndex = updatedComments.findIndex(
              (comment) => comment.id === newComment.id
            );

            if (existingCommentIndex === -1) {
              updatedComments.push(newComment);
            } else {
              const existingComment = updatedComments[existingCommentIndex];
              const existingReplies = existingComment.replies?.comments || [];
              const newReplies = newComment.replies?.comments || [];

              const mergedReplies = [
                ...existingReplies,
                ...newReplies.filter(
                  (nr) => !existingReplies.some((er) => er.id === nr.id)
                )
              ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

              updatedComments[existingCommentIndex] = {
                ...existingComment,
                replies: {
                  ...existingComment.replies,
                  comments: mergedReplies
                }
              };
            }
          });

          return updatedComments;
        });

        setNextOffset(value.offset + result.length);

        const paginateState = (result.length === 0 || (comments.length + result.length) >= totalCount || (result.length < value.limit)) ? 'disable' : 'hidden';

        setPaginate(paginateState);
        setTabChanged(false);
      }
    } catch (error) {
      // Stay silent if we can't load
      logger.warn('onLoadComments: error', error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }

      trackLoadTime();
    }
  }, [loading, article.id, comments, setComments, setLoading, setPaginate, isMounted, trackLoadTime, tabChanged]);

  useEffect(() => {
    onLoadComments(query);
  }, [query]);

  useEffect(() => {
    if (jumpToComment) {
      openCommentsOverlay();
    }
  }, [jumpToComment]);

  useEffect(() => {
    postStoredComment();
    trackCommentImpression();
  }, []);

  useEffect(() => {
    if (window && window.PageCriteria && window.PageCriteria.readerCommentsCount) {
      setCommentsCount(window.PageCriteria.readerCommentsCount);
    }
  }, [window]);

  const onLoadMore = () => {
    if (!loading && paginate === 'hidden' && comments.length > 0) {
      setPaginate('show');

      let offset = nextOffset;
      let limit = PAGE_SIZE;

      if (jumpToComment && jumpToComment.offset && firstFetchMore) {
        offset = 0;
        limit = Math.min(jumpToComment.offset, PAGE_SIZE);

        setMissingCommentsOffset(PAGE_SIZE);
        setNeedToFetchMissing(jumpToComment.offset > PAGE_SIZE);
        setFirstFetchMore(false);
      } else if (needToFetchMissing && jumpToComment) {
        const remaining = jumpToComment.offset - missingCommentsOffset;

        offset = missingCommentsOffset;
        limit = Math.min(remaining, PAGE_SIZE);

        const nextMissingOffset = missingCommentsOffset + limit;

        setMissingCommentsOffset(nextMissingOffset);
        setNeedToFetchMissing(jumpToComment.offset > nextMissingOffset);
      }

      trackCommentPaginationClick(Math.floor(offset / 10));
      setQuery({
        ...query,
        limit,
        offset
      });
    }
  };

  const onTabChange = (event) => {
    setTabChanged(true);
    trackCommentTab(TRACKING_TAB_VALUES[event.target.value]);
    setNextOffset(0);
    setQuery({
      offset: 0,
      tab: event.target.value
    });
  };

  const updateComments = (commentId, updatedReplies) => {
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === commentId ?
          {...comment,
            replies: {...comment.replies,
              comments: updatedReplies}} :
          comment
      )
    );
  };

  return (
    <div className={styles.container} id='reader-comments-container'>
      <div className={styles.commentHeader}>
        <h3>Comments &#40;{formatNumber(commentsCount)}&#41;</h3>

        {article.canComment ?
          <div className={styles.commentArea}>
            <InputForm />
            {notification && notification.replyId === 'root' && (
              <Notification type={notification.type} />
            )}
          </div> :
          <p className={styles.articleEnded}>Commenting on this article has ended</p>
        }

        <ul className={styles.commentTabs}>
          <Tab currentTab={query.tab} loading={loading} onChange={onTabChange} tab='newest'>Newest</Tab>
          <Tab currentTab={query.tab} loading={loading} onChange={onTabChange} tab='oldest'>Oldest</Tab>
          <Tab currentTab={query.tab} loading={loading} onChange={onTabChange} tab='bestRated'>Best rated</Tab>
          <Tab currentTab={query.tab} loading={loading} onChange={onTabChange} tab='worstRated'>Worst rated</Tab>
          {isUserLoggedIn() && <Tab currentTab={query.tab} loading={loading} onChange={onTabChange} tab='myComments'>My Comments</Tab>}
        </ul>
      </div>

      <div className={styles.commentList}>
        <CommentList
          canComment={article.canComment}
          comments={comments}
          loading={loading}
          onShowMore={onLoadMore}
          showMore={paginate !== 'disable'}
          tab={query.tab}
          updateComments={updateComments}
        />

        <div className={styles.tncs}>
          <a href='https://www.dailymail.co.uk/home/article-1388146/Terms.html'>Terms</a>&nbsp;|&nbsp;
          <a href='https://www.dailymail.co.uk/home/article-7759273/Privacy-Cookies-Policy-Policy.html'>Privacy</a>&nbsp;|&nbsp;
          <a href='https://www.dailymail.co.uk/home/contactus/index.html'>Feedback</a>
        </div>

        <div className={classnames(styles.paginator, paginate === 'disable' && styles.hide)}>
          {paginate === 'show' && <LoadingDots />}
        </div>
      </div>

      {(paginate !== 'show' && loading) && (<Spinner />)}
    </div>
  );
};
